<template>
    <section class="talleres-ver-documentos scroll-none overflow-auto pt-3">
        <titulo-divisor titulo="Carpetas" class="mx-0">
            <div class="row">
                <div class="col-auto  pl-1">
                    <button
                        class="btn btn-general f-12 px-3"
                        :class="permitAction('taller.crear.carpetas') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click="functionPermitAction('taller.crear.carpetas', crearCarpeta)"
                    >Crear carpeta</button>
                </div>
            </div>
        </titulo-divisor>
        <div class="row">
            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                <div class="br-4 border">
                    <p class="text-general f-14 f-600 text-center py-3 border-bottom-g mb-2 shadow29">Carpetas de taller</p>
                    <div class="listado-carpetas px-2 pb-3 overflow-auto custom-scroll mr-1 mb-2">
                        <div 
                        v-for="data in folders" 
                        :key="data.id" 
                        class="carpeta border br-8 p-2 d-flex mb-3" 
                        :class="{'active': $route.params.id_folder == data.id }"
                        @click="verArchivos(data.id)"
                        >
                            <div class="d-middle tres-puntos flex-fill">
                                <i class="icon-folder f-20" />
                                <p class="tres-puntos f-12 pr-2"> {{ data.nombre }} </p>
                            </div>
                            <div class="cant-archivos bg-general rounded-pill px-2 py-1 text-center text-white f-12 my-auto d-middle-center">
                                {{ data.cantidad_archivos }}
                            </div>
                             <el-tooltip placement="bottom-start" effect="light" visible-arrow popper-class="like-buttons">
                                <template #content>
                                    <p 
                                        class="px-1 py-2 item"
                                        :class="permitAction('taller.editar.carpetas') ? 'cr-pointer' : 'cr-not-allowed'" 
                                        @click="functionPermitAction('taller.editar.carpetas', editarCarpeta, [data])"
                                    >Editar</p>
                                    <p 
                                        class="px-1 py-2 item"
                                        :class="permitAction('taller.editar.carpetas') ? 'cr-pointer' : 'cr-not-allowed'" 
                                        @click="functionPermitAction('taller.editar.carpetas', eliminarCarpeta, [data.id])"
                                    >Eliminar</p>
                                </template>
                                <i class="icon-dots-vertical my-auto f-20" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>    
            </div>
            <div class="col-lg-8 mb-3 mb-lg-0">
                <div class="br-4 border">
                    <div class="row border-bottom-g f-600 f-14 mx-0 mb-2 shadow29">
                        <div class="col-md-3 col-lg-4 my-auto text-general">
                            <p class="py-3">Archivos</p>
                        </div>
                        <div class="col-md-3 col-lg-3 my-auto text-general">
                            <p class="py-3">Responsable</p>
                        </div>
                        <div class="col-md-4 col-lg-3 text-center my-auto text-general">
                            <p class="">Fecha de creación</p>
                            
                        </div>
                        <div class="col-md-2 col-lg-2 my-auto text-center icon-option">
                            <i 
                                class="icon-plus-box f-20q"
                                :class="permitAction('taller.carpetas.agregar.archivo') ? 'cr-pointer' : 'cr-not-allowed'" 
                                @click="functionPermitAction('taller.carpetas.agregar.archivo', ()=> {$route.params.id_folder ? agregarArchivo() :() => {}})"
                            />
                        </div>
                    </div>
                    <router-view />
                </div>                
            </div>
        </div>
        <!-- Partials -->
        <modal-agregar-carpeta  ref="abrirAgregarCarpeta" @save="saveFolder" @update="updateFolder"/>
        <modal-eliminar ref="abrirEliminarCarpeta" title="Eliminar carpeta" mensaje="¿Está seguro que quiere eliminar esta carpeta?" @delete="deleteFolder"/>
        <modal-eliminar ref="abrirEliminarArchivo" title="Eliminar archivo" mensaje="¿Está seguro que quiere eliminar este archivo?" />
        <modal-adjuntos-general ref="abrirAgregarArchivoCarpeta" @saveFile  ="saveFile" message="Archivo con un tamaño menor a 5MB" />
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    components: { 
      modalAgregarCarpeta: () => import('./partials/modalAgregarCarpeta.vue')
    },
    data(){
        return{
            folder: {
                id: null,
            },
        }
    },
    computed:{
        ...mapGetters({
            folders: 'talleres/ver_documentos/folders',
            key: 'files/files/key'
        }),
        idTaller(){
            return this.$route.params?.id
        },
        idFolder: {
            get(){
                return this.$store.getters['talleres/ver_documentos/id_folder']
            },
            set(val){
                this.$store.commit('talleres/ver_documentos/set_id_folder', val)
            }
        }
    },
    async created(){
        this.replaceBreadcumb({position: 2, breadcumb: 'talleres.ver.documentos'})
        this.$store.commit('talleres/ver_documentos/set_id_taller', this.idTaller)
        await this.Action_get_folders()
        this.idFolderParam = this.$route.params?.id_folder
        this.idFolder = this.idFolderParam ?? this.folders?.[0]?.id
        if(this.idFolder) {
            this.navigateTo({ name: 'talleres.ver.documentos.archivos', params: { id_folder: this.idFolder } })
            this.verArchivos(this.idFolder)
        }
    },
    methods:{
        ...mapActions({
            replaceBreadcumb: 'navigation/breadcumbs/replaceBreadcumb',
            Action_get_folders: 'talleres/ver_documentos/Action_get_folders',
            Action_save_folder: 'talleres/ver_documentos/Action_save_folder',
            Action_update_folder: 'talleres/ver_documentos/Action_update_folder',
            Action_delete_folder: 'talleres/ver_documentos/Action_delete_folder',
            Action_get_files: 'talleres/ver_documentos/Action_get_files',
            Action_save_file: 'talleres/ver_documentos/Action_save_file',
            uploadFile: 'files/files/Action_save_file_to_aws'
        }),
        crearCarpeta(){
            this.$refs.abrirAgregarCarpeta.toggle()
        },
        editarCarpeta(data){
            this.$refs.abrirAgregarCarpeta.edit(data)
        },
        eliminarCarpeta(id){
            this.folder.id = id
            this.$refs.abrirEliminarCarpeta.toggle()
        },
        agregarArchivo(){
            this.$refs.abrirAgregarArchivoCarpeta.toggle()
        },
        async verArchivos(id_folder = null){
            if(!id_folder) return this.$router.push({ name: 'talleres.ver.documentos'})
            this.$router.push({ name: 'talleres.ver.documentos.archivos', params: { id_folder } })
            this.idFolder = id_folder
            await this.Action_get_files(() => {
                this.verArchivos(this.folders?.[0]?.id)
            })
        },
        async saveFile([file]){
            await this.uploadFile({
                file: file.filesToUpload,
                path: `/talleres/${this.idTaller}/carpetas/${this.idFolder}/archivos`,
            })
            await this.Action_save_file({
                nombre: file.fileName,
                id_tipo: file.fileType,
                ruta: this.key
            })
            this.$refs.abrirAgregarArchivoCarpeta.toggle()
        },
        async saveFolder(folder){
            await this.Action_save_folder(folder)
            this.$refs.abrirAgregarCarpeta.toggle()
        },
        async updateFolder(folder){
            await this.Action_update_folder(folder)
            this.$refs.abrirAgregarCarpeta.toggle()
        },
        async deleteFolder(){
            await this.Action_delete_folder(this.folder.id)
            this.verArchivos(this.folders?.[0]?.id)
        },
    }
}
</script>

<style lang="scss" scoped>
.talleres-ver-documentos{
    height: calc(100vh - 255px);
    .listado-carpetas{
        min-height: 430px;
        max-height: 430px;
        .carpeta{
            .cant-archivos{
                height: 16px;
            }
            &:hover, &.active{
                background: var(--color-general);
                color: #fff !important;
                .cant-archivos{
                    background: white;
                    color: var(--color-general) !important;
                }
            }
        }
    }
    .listado-archivos{
        min-height: 430px;
        max-height: 430px;
        .archivo{
            &:hover{
                background-color: var(--color-f5);
            }
        }
    }
}
</style>